/*
 * @Author: N0ts
 * @Date: 2023-04-13 17:19:07
 * @Description: 搜索 api
 * @FilePath: /aggregate-search/src/api/search/search.ts
 * @Mail：mail@n0ts.top
 */

import http from "@/api/request";
import * as types from "./types";

export default {
    baidu(params: types.IBaiduSearch) {
        return http<types.ISearchResult>({
            method: "GET",
            url: "/baidu/search",
            params
        });
    },
    bing(params: types.IBaiduSearch) {
        return http<types.ISearchResult>({
            method: "GET",
            url: "/bing/search",
            params: {
                ...params,
                first: params.pageIndex
            }
        });
    },
    google(params: types.IBaiduSearch) {
        return http<types.ISearchResult>({
            method: "GET",
            url: "/google/search",
            params
        });
    }
};
