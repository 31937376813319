/*
 * @Author: N0ts
 * @Date: 2023-04-13 17:17:19
 * @Description: axios 配置
 * @FilePath: /aggregate-search/src/api/request.ts
 * @Mail：mail@n0ts.top
 */
import axios, { AxiosRequestConfig } from "axios";

const baseURL = "https://api.n0ts.top";

axios.interceptors.request.use(
    function (config) {
        return config;
    },
    function (error) {
        return Promise.reject(error);
    }
);

axios.interceptors.response.use(
    function (response) {
        return response.data;
    },
    function (error) {
        return Promise.reject(error);
    }
);

export default function <T>(config: AxiosRequestConfig) {
    config.baseURL = baseURL;
    return new Promise<T>((resolve, reject) => {
        axios<T>(config).then((res) => resolve(res.data));
    });
}
