/*
 * @Author: N0ts
 * @Date: 2023-04-13 00:38:20
 * @Description: main
 * @FilePath: /aggregate-search/src/main.ts
 * @Mail：mail@n0ts.top
 */

import { createApp } from "vue";
import "@/assets/css/global.less";

import App from "./App.vue";

createApp(App).mount("#app");
